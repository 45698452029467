 .App-logo {
    height: 25vmin;
    pointer-events: none;
  }

  .App-logo-secondary {
    height: 38vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    /* .App-logo {
      animation: App-logo-spin infinite 1s linear;
    } */
    .App-logo-secondary {
      animation: App-logo-spin infinite 1s linear;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  