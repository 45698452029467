.variationArtist {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-left: -6%;
  padding-top: 15px;
  overflow: 'hidden';
}

.artist {
  display: flex;
  flex-direction: row;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 0;
}

.artist-sm {
  display: flex;
  flex-direction: row;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 0;
}

.artistSelected {
  display: flex;
  flex-direction: row;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  box-shadow: 0 0 15px #37dbf6;
  transform: scale(1.02);
  z-index: 100;
}

.variationDiv {
  position: absolute;
  height: 60px;
  margin-top: 10px;
  right: -30px;
  display: inline-flexbox;
  justify-content: space-around;
  flex-direction: column;
  overflow: 'hidden';
  width: 360px;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: white;
}

.artist:hover {
  width: 55px;
  height: 55px;
}
