@keyframes blur {
    0%,
    90% {
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
    }
    50% {
      -webkit-filter: blur(50px);
      -moz-filter: blur(50px);
      -o-filter: blur(50px);
      -ms-filter: blur(50px);
    }
  }

  @keyframes blurLanding {
    90% {
        -webkit-filter: blur(30px);
        -moz-filter: blur(30px);
        -o-filter: blur(30px);
        -ms-filter: blur(30px);
    }
    50% {
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
    }
  }