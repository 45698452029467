/* FONTS */
@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Regular.ttf');
}

@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Light.ttf');
  font-weight: 300;
}

::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}