.react-calendar {
  width: 350px;
  max-width: 100%;
  background: var(--rsSurface);
  color: var(--rsSecondary);
  border: 1px solid var(--rsSecondary);
  line-height: 1.125em;
  font-family: Nunito;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: var(--rsSecondary);
}
.react-calendar__navigation button:enabled:focus {
  background-color: var(--rsSurface);
  color: var(--rsSecondary);
}
.react-calendar__navigation button:enabled:hover {
  background-color: var(--rsSurfaceHover);
  color: var(--rsSecondary);
}
.react-calendar__navigation button[disabled] {
  background-color: var(--rsSurface);
  color: var(--rsDisabled);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(1em) calc(0.6666666666666666em);
}
.react-calendar__month-view__days__day--weekend {
  color: var(--rsMajor);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--rsSecondary);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  color: var(--rsSecondary);
}
.react-calendar__tile:disabled {
  background-color: var(--rsSurface);
  color: var(--rsDisabled);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--rsMinor);
  color: var(--rsSecondary);
}
.react-calendar__tile--now {
  /* background: var(--rsMinor); */
  color: var(--rsSecondary);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--rsMinor);
  color: var(--rsSecondary);
}
.react-calendar__tile--hasActive {
  background: var(--rsMinor);
  color: var(--rsSecondary);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--rsMinor);
  color: var(--rsSecondary);
}
.react-calendar__tile--active {
  background: var(--rsMinor);
  color: var(--rsSecondary);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--rsMinor);
  color: var(--rsSecondary);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  /* background-color: var(--rsSurfaceHover); */
  color: var(--rsSecondary);
}
